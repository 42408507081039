<div class="container-fluid">

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-light d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div> -->


    <div class="row">
        <div class="col-lg-12">
            <!-- <form> -->
            <div class="row">
                <div class="col-lg-4">
                    <div class="mb-3">
                        <label class="form-label">From Date -To Date</label>
                        <!-- <input type="text" class="form-control date" placeholder="Select Date"
                            [(ngModel)]="selectedDateRange" (bsValueChange)="onDateChange($event)"
                            (click)="hidden = !hidden" name="daterange" autocomplete="off" bsDaterangepicker
                            [bsConfig]="datePickerConfig" [bsConfig]="datePickerConfig" /> -->

                        <div class="position-relative">
                            <input type="text" class="form-control date"
                                [value]="selectedDateRange ? (formatDateRange(selectedDateRange)) : ''"
                                placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                                [bsConfig]="datePickerConfig" (bsValueChange)="onDateChange($event)"
                                [(bsValue)]="selectedDateRange" (keydown)="preventManualInput($event)" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                            <!-- Clear icon -->
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="selectedDateRange.length>0"
                                (click)="clearDate()"></span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-flex align-items-center sm-btn">
                    <button class="btn btn-primary btn-md mt-2" (click)="filterReadings('filter')"
                        [disabled]="selectedDateRange.length>0? false:true">
                        Filter
                    </button>
                    <button class="btn btn-outline-primary btn-md mt-2 ms-2" (click)="filterReadings('reset')"
                        [disabled]="selectedDateRange.length>0? false:true"> Clear
                    </button>

                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <div *ngIf="consumtionGridView">
                            <kendo-grid [data]="consmptiongridData" [reorderable]="true" filterable="menu"
                                [columnMenu]="{ filter: true }" scrollable="scrollable" [resizable]="true"
                                [pageSize]="pageSize" [pageable]="{
                          buttonCount: buttonCount,
                          info: info,
                          type: type,
                          pageSizes: pageSizes,
                          previousNext: previousNext
                        }" [selectable]="selectableSettings" [filter]="consumptionRechargesState.filter" [skip]="skip"
                                [sort]="sort" [columnMenu]="{ filter: true }"
                                (filterChange)="onConsumptionFilterChange($event)" (sortChange)="onSortChange($event)"
                                [sortable]="true" [resizable]="true" (selectionChange)="onSelect($event)"
                                (pageChange)="consumptionPageChange($event)"
                                (dataStateChange)="consumptionStateChange($event)">

                                <ng-template kendoGridToolbarTemplate>
                                    <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                        [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->

                                    <div class="search_icon">
                                        <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                            (valueChange)="onValueChange($event)"
                                            (keydown.enter)="onSearchConsumptionUnits()" [style.height.px]="35"
                                            [style.height.px]="35" [(ngModel)]="searchedValue"
                                            placeholder="Search in all columns...">
                                        </kendo-textbox>

                                        <button class="btn btn-primary btn-md border-left-none"
                                            (click)="onFilter($event)">
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div>
                                    <kendo-grid-spacer></kendo-grid-spacer>


                                    <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                        (click)="onExport('Excel')">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                    </button>

                                    <!-- button class="btn btn-light" (click)="onExport('Pdf')">
                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                                </ng-template>

                                <!-- Pdf   start-->
                                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                    <kendo-grid
                                        [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:consmptiongridData.data"
                                        #pdfGrid>
                                        <kendo-grid-column field="utility" title="Uility">
                                            <ng-template kendoGridCellTemplate let-dataItem>

                                                <span></span> {{ dataItem.utility =='Electricity' ?
                                                dataItem.utility+ '('+(dataItem.isEB==true?'EB':'DG')+')'
                                                :dataItem.utility
                                                }}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="meterSrNo" title="Device Sr No"></kendo-grid-column>
                                        <kendo-grid-column field="macAddress" title="Meter No"></kendo-grid-column>
                                        <kendo-grid-column field="consumptionUnits" title="Consumption"
                                            filter="numeric">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.utility=='Water'?dataItem.consumptionUnits+' L':
                                                dataItem.utility=='Electricity'?
                                                dataItem.consumptionUnits+' kWh':
                                                dataItem.utility=='Gas'?dataItem.consumptionUnits+' m³':''
                                                }}
                                            </ng-template>
                                        </kendo-grid-column>

                                        <kendo-grid-column field="unitRate" title="Unit Rate">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{ dataItem.unitRate | currency: 'INR':'symbol':'1.2-2' }}
                                            </ng-template>
                                        </kendo-grid-column>

                                        <kendo-grid-column field="unitPrice" title="Amount Deducted" filter="numeric">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{ dataItem.amountDeducted | currency: 'INR':'symbol':'1.2-2' }}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="availableBalance" title="Available Balance">

                                        </kendo-grid-column>
                                        <!-- <kendo-grid-column field="availableBalance" title="Available Balance">
                                        </kendo-grid-column> -->

                                        <kendo-grid-column field="initialMeterReading" title="Initial Reading"
                                            filter="numeric"></kendo-grid-column>

                                        <kendo-grid-column filter="numeric" field="finalMeterReading"
                                            title="Final Reading"></kendo-grid-column>

                                        <kendo-grid-column field="initialMeterReadingEB" title="Initial MeterReading EB"
                                            filter="numeric"></kendo-grid-column>

                                        <kendo-grid-column field="initialMeterReadingDG" title="Initial MeterReading DG"
                                            filter="numeric"></kendo-grid-column>

                                        <!-- kendo-grid-column filter="numeric" field="initialMeterReadingEB"
                                            title="Final MeterReading EB"></kendo-grid-column> -->


                                        <kendo-grid-column filter="numeric" field="finalMeterReadingEB"
                                            title="Final MeterReading EB"></kendo-grid-column>

                                        <kendo-grid-column filter="numeric" field="finalMeterReadingDG"
                                            title="Final MeterReading DG"></kendo-grid-column>
                                        <kendo-grid-column field="previousReading_Date" filter="date"
                                            title="initial Reading Date"></kendo-grid-column>

                                        <kendo-grid-column field="finalReadin_gDate" filter="date"
                                            title="Final Reading Date"></kendo-grid-column>

                                        <kendo-grid-column field="Consumption_Date" title="Consumption Date"
                                            filter="date" [filterable]="true">

                                        </kendo-grid-column>

                                        <kendo-grid-column field="serviceName" title="Service Name"></kendo-grid-column>

                                        <kendo-grid-column field="gstPer" title="GST Percentage"></kendo-grid-column>

                                        <kendo-grid-column field="gstAmount" title=" GST Amount "></kendo-grid-column>

                                        <kendo-grid-column field="totalAmount" title=" Bill Amount"></kendo-grid-column>

                                        <kendo-grid-excel fileName="ConsumptionHistory.xlsx"></kendo-grid-excel>
                                        <kendo-grid-pdf fileName="ConsumptionHistory.pdf" [allPages]="true"
                                            paperSize="A4" [landscape]="true"></kendo-grid-pdf>
                                    </kendo-grid>
                                </div>
                                <!-- Pdf Edrt-->

                                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                    [width]="40"></kendo-grid-checkbox-column>
                                <kendo-grid-column field="utility" title="Utility">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span>
                                            {{ dataItem.utility === 'Electricity'
                                            ? dataItem.utility
                                            : (dataItem.utility === '' || dataItem.utility == null ? 'N/A' :
                                            dataItem.utility) }}
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="meterSrNo" title="Device Sr No"></kendo-grid-column>

                                <kendo-grid-column field="macAddress" [hidden]="true" title="Mac Address">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span>
                                            {{ dataItem?.macAddress === null || dataItem?.macAddress === '' ||
                                            dataItem?.macAddress === undefined ? 'N/A' : dataItem.macAddress }}
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="consumptionUnits" title="Consumption" filter="numeric">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{
                                        dataItem.consumptionUnits == null || dataItem.consumptionUnits === ''
                                        ? '-'
                                        : dataItem.utility == 'Water'
                                        ? dataItem.consumptionUnits + ' L'
                                        : dataItem.utility == 'Electricity'
                                        ? '-'
                                        : dataItem.utility == 'Gas'
                                        ? dataItem.consumptionUnits + ' m³'
                                        : '-'
                                        }}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="consumptionUnitsEB" title="EB Consumption Units "
                                    filter="numeric">
                                    <ng-template kendoGridCellTemplate let-dataItem>

                                        {{ dataItem.consumptionUnitsEB !== null ? dataItem.utility == 'Electricity'?
                                        dataItem.consumptionUnitsEB + ' kWh':'-'
                                        : '-' }}

                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="consumptionUnitsDG" title=" DG Consumption Units "
                                    filter="numeric">
                                    <ng-template kendoGridCellTemplate let-dataItem>

                                        {{ dataItem.consumptionUnitsDG !== null ? dataItem.utility == 'Electricity'?
                                        dataItem.consumptionUnitsDG + ' kWh':'-'
                                        : '-' }}
                                    </ng-template>
                                </kendo-grid-column>



                                <kendo-grid-column [hidden]="true" field="unitRate" title="Unit Rate">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.unitRate | currency: 'INR':'symbol':'1.2-2' }}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column field="unitPrice" title="Amount Deducted" filter="numeric">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.amountDeducted | currency: 'INR':'symbol':'1.2-2' }}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="availableBalance" title="Available Balance" filter="numeric">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2' }}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column [hidden]="true" field="initialMeterReading" title="Initial Reading"
                                    filter="numeric"></kendo-grid-column>

                                <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReading"
                                    title="Final Reading"></kendo-grid-column>

                                <kendo-grid-column [hidden]="true" filter="numeric" field="initialMeterReadingEB"
                                    title="Initial Meter Reading EB">
                                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.initialMeterReadingEB
                                        !==
                                        null ?
                                        dataItem.utility == 'Electricity'? dataItem.initialMeterReadingEB + ' kWh':'-'
                                        : '-' }}
                                    </ng-template>
                                </kendo-grid-column>


                                <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReadingEB"
                                    title="Final MeterReading EB">
                                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.finalMeterReadingEB !==
                                        null ?
                                        dataItem.utility == 'Electricity'? dataItem.finalMeterReadingEB + ' kWh':'-'
                                        : '-' }}
                                    </ng-template>



                                </kendo-grid-column>
                                <kendo-grid-column [hidden]="true" filter="numeric" field="initialMeterReadingDG"
                                    title="Initial Meter Reading DG">
                                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.initialMeterReadingDG
                                        !== null ?
                                        dataItem.utility == 'Electricity'? dataItem.initialMeterReadingDG + ' kWh':'-'
                                        : '-' }}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReadingDG"
                                    title="Final Meter Reading DG">
                                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.finalMeterReadingDG !==
                                        null ?
                                        dataItem.utility == 'Electricity'? dataItem.finalMeterReadingDG + ' kWh':'-'
                                        : '-' }}
                                    </ng-template>

                                </kendo-grid-column>

                                <kendo-grid-column field="previousReadingDate" [hidden]="true"
                                    title="Initial Reading Date" filter="date" [filterable]="true">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.previousReadingDate | date: 'dd MMM yyyy hh:mm: a' }}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column field="finalReadingDate" [hidden]="true" title="Final Reading Date"
                                    filter="date" [filterable]="true">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.finalReadingDate | date: 'dd MMM yyyy hh:mm: a' }}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column field="finalReadingDate" title="Consumption Date" filter="date"
                                    [filterable]="true">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.finalReadingDate | date: 'dd MMM yyyy hh:mm: a' }}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column [hidden]="true" field="serviceName"
                                    title="Service Name"></kendo-grid-column>

                                <kendo-grid-column [hidden]="true" field="gstPer"
                                    title="GST Percentage"></kendo-grid-column>

                                <kendo-grid-column [hidden]="true" field="gstAmount"
                                    title=" GST Amount "></kendo-grid-column>

                                <kendo-grid-column [hidden]="true" field="totalAmount"
                                    title=" Bill Amount"></kendo-grid-column>


                                <kendo-grid-command-column [columnMenu]="false" [width]="60">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="d-flex">
                                            <!-- Consumption View Icon -->
                                            <span kendoTooltip title="Go to Consumption View"
                                                (click)="viewconsumption(dataItem)"
                                                style="cursor: pointer; font-size: 20px;">
                                                <i class="fas fa-info-circle text-success"></i>
                                            </span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-command-column>


                            </kendo-grid>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>