import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../shared.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-rp-alerts',
  templateUrl: './rp-alerts.component.html',
  styleUrl: './rp-alerts.component.scss'
})
export class RpAlertsComponent {
  isBill: boolean = false;
  isInvoice: boolean = false;
  PrjectName: any;
  currentUserData: any;
  @Input() paymentData: any;
  constructor(private modalService: BsModalService,
    private loaderService: LoaderService, private SharedService: SharedService
  ) {
    const currentUserString = localStorage.getItem('currentUser')
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.PrjectName = this.currentUserData?.project?.name;
    //name
    // console.log(this.paymentData)
  }


  closeAlert() {
    this.modalService.hide();
  }

  ngAfterViewInit() {
    this.loaderService.setLoading(false);
    console.log("this.paymentData------------->", this.paymentData)
    this.isBill = this.paymentData.IsBill
    this.isInvoice = this.paymentData.isInvoice

  }
  downloadReceipt() {
    console.log(this.paymentData);
    this.loaderService.setLoading(true);

    this.SharedService.downloadPaymentReceipt(this.paymentData).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = 'PaymentReceipt.pdf'; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.modalService.hide();

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }





}