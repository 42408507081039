<div class="modal-content">
    <!-- <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Create New Master</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
    </div> -->

    <div class="modal-body">
        <div class="main-payment">
            <div class="align-center">
                <div class="tick-success">
                    <img src="../../../assets/images/tick-circle.png">
                </div>
            </div>
            <div class="text-center mt-5">
                <h3>Payment Success </h3>
                <p class="text-muted">Your payment has been successfully done</p>
                <hr class="mx-auto">
            </div>
            <div class="text-center ">
                <p *ngIf="!isBill && !isInvoice" class="text-muted font-size-18">Recharge Amount</p>
                <p *ngIf="isBill" class="text-muted font-size-18">Bill Amount</p>
                <p *ngIf="isInvoice" class="text-muted font-size-18">Total Amount</p>
                <h3> {{paymentData?.lastRechargeAmount!=undefined?(paymentData.lastRechargeAmount
                    |currency:'INR':true):'0'}}
                </h3>
                <!-- <h3><span>₹</span>{{paymentData?.lastRechargeAmount}} </h3> -->
                <hr class="mx-auto">
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="p-2 mt-3 mx-2">
                        <table class="table">

                            <tr>
                                <td class="text-muted">Paid to</td>
                                <td class="float-end font-weight-bolder  font-size-14">{{PrjectName}}</td>
                            </tr>
                            <tr>
                                <td class="text-muted">Flat#</td>
                                <td class="float-end font-weight-bolder ">{{paymentData?.residentialUnit}}</td>
                            </tr>
                            <tr>
                                <td class="text-muted">Payment ID</td>
                                <td class="float-end font-weight-bolder ">{{paymentData?.paymentId}}</td>
                            </tr>

                            <tr>
                                <td class="text-muted">Date & Time</td>
                                <p>
                                </p>
                                <td class="float-end font-weight-bolder ">{{paymentData?.lastRechargeDate!=null?
                                    (paymentData?.lastRechargeDate | date: 'dd MMM yyyy hh:mm a')
                                    :'' }}</td>
                            </tr>

                        </table>


                    </div>
                </div>

            </div>

            <div class=" row mt-4">
                <div class="col-lg-12 w-80 mx-auto text-center d-flex flex-column">
                    <p class="text-primary">Thank you for choosing Ripple Services</p>
                    <button class="btn btn-primary" *ngIf="!paymentData.isWallet" (click)="downloadReceipt()"><i
                            class="bx bx-download me-2"></i>
                        Download Receipt</button>
                    <button class="btn btn-outline-primary mt-2 mb-4" (click)="closeAlert()"> Ok</button>
                </div>

            </div>
        </div>
    </div>
    <!--  <div class="modal-footer">

        <button type="button" class="btn btn-primary waves-effect waves-light">Update Master</button>
        <button type="button" class="btn btn-primary waves-effect waves-light">Save
            Master</button>
    </div> -->
</div>