import { createAction, props } from '@ngrx/store';
import { meter } from './meters.models';

// meter creation
export const Getmeter = createAction(
  '[meter] getmeter',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getmeterSuccess = createAction(
  '[meter] Getmeter Success',
  props<{ getmeterConfigResponse: any }>()
);
export const getmeterFailure = createAction(
  '[meter] Getmeter Failure',
  props<{ getmeterError: any }>()
);

// Get Meter On/Off Hostory by Admin


export const getAdminmeteronOffHistory = createAction(
  '[meter] getmeteronOffHistory',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    FromDate: string;
    ToDate: string;
    orderBy?: string;
    MeterId?: string
  }>()
);
export const geAdminmeteronOffHistorySuccess = createAction(
  '[meter] GetmeteronOffHistory Success',
  props<{ getAdminmeteronOffHistoryResponse: any }>()
);
export const geAdminmeteronOffHistoryFailure = createAction(
  '[meter] GetmeteronOffHistory Failure',
  props<{ getAdminmeteronOffHistoryError: any }>()
);

//meter On Off History  Export to excel
export const getAdminmeteronOffHistoryExport = createAction(
  '[meter] getmeteronOffHistory Expor',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
    MeterId?: string
  }>()
);
export const geAdminmeteronOffHistoryExportSuccess = createAction(
  '[meter] GetmeteronOffHistory Expor Success',
  props<{ getAdminmeteronOffHistoryExportResponse: any }>()
);
export const geAdminmeteronOffHistoryExportFailure = createAction(
  '[meter] GetmeteronOffHistory  Expor Failure',
  props<{ getAdminmeteronOffHistoryExportError: any }>()
);






//all master data
export const GetAllmeter = createAction(
  '[meter] getAllmeter',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllmeterSuccess = createAction(
  '[meter] GetAllmeter Success',
  props<{ getAllmeterConfigResponse: any }>()
);
export const getAllmeterFailure = createAction(
  '[meter] GetAllmeter Failure',
  props<{ getAllmeterError: any }>()
);

// meter creation
export const getMeterDetails = createAction(
  '[meter] getMeterConsumption',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    MeterId?: string;
    FromDate: string;
    ToDate: string;
    orderBy?: string;
  }>()
);

export const getmeterConsumptionSuccess = createAction(
  '[meter] getMeterConsumption Success',
  props<{ getmeterConsumptionResponse: any }>()
);
export const getmeterConsumptionFailure = createAction(
  '[meter] getMeterConsumption Failure',
  props<{ getmeterConsumptionError: any }>()
);

export const GetAllMeterDetails = createAction(
  '[meter] GetAllmeterConsumptionHistory',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    MeterId: string;
    FromDate: string;
    ToDate: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getAllmeterConsumptionSuccess = createAction(
  '[meter] getAllmeterConsumption Success',
  props<{ getAllmeterConsumptionResponse: any }>()
);
export const getAllmeterConsumptionFailure = createAction(
  '[meter] getAllmeterConsumption Failure',
  props<{ getAllmeterConsumptionError: any }>()
);

export const updateMeterStatus = createAction(
  '[meter] updateMeterStatus',
  props<{ request: any }>()
);
export const updateMeterStatusSuccess = createAction(
  '[meter] updateMeterStatus Success',
  props<{ updateMeterStatusResponse: any }>()
);
export const updateMeterStatusFailure = createAction(
  '[meter] updateMeterStatus Failure ',
  props<{ updateMeterStatusError: any }>()
);

export const checkMeterStatus = createAction(
  '[meter] checkMeterStatus',
  props<{ request: any }>()
);
export const checkMeterStatusSuccess = createAction(
  '[meter] checkMeterStatus Success',
  props<{ checkMeterStatusResponse: any }>()
);
export const checkMeterStatusFailure = createAction(
  '[meter] checkMeterStatus Failure ',
  props<{ checkMeterStatusError: any }>()
);

export const resetMeterDetailsState = createAction('[resetState ] resetState');

//store reset
export const resetState = createAction('[App] Reset State');
//store reset
export const meterRedaingExportReset = createAction('[App] Reset meter export State');