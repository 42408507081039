import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Subscription, zip } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SweetAlertIcon } from '../../constants/enum';
import { LoaderService } from '../../../core/services/loader.service';
import { Router } from '@angular/router';
import {
  getAllWalletHistorylist,
  getWalletHistorylist,
  resetState,
} from '../../../store/Wallet/wallet.action';
import { getWalletHistoryResponse } from '../../../store/Wallet/wallet-selector';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
  ExcelExportEvent,
} from '@progress/kendo-angular-grid';
import { WalletService } from '../../../core/services/wallet.services';
import { table_pageSize } from '../../constants/templatesNames';
import { SVGIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { Helper } from '../../../core/helpers/helper';
import _ from 'lodash';
@Component({
  selector: 'app-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrl: './wallet-history.component.scss',
})
export class WalletHistoryComponent {
  @Output() countChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  walletQueryString: string = '';
  walletOrderByQuery: string = '';
  walletSearch: any = '';
  currentPage: number = 1;
  getAllWalletHistoryList: any[] = [];
  getWalletHistoryList: any = [];
  totalwallethistoryCount: number = 0;
  public sort: SortDescriptor[] = [];
  public walletHistorygridData: GridDataResult = { data: [], total: 0 };
  public wallet_historyPageSize = 10;
  public skip = 0;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  walletDetails: any;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  mySelectionWallet: any = [];
  public walletHisoryState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  datePickerConfig: Partial<BsDatepickerConfig>;
  walletDetailsId: any;
  currentUserData: any;
  billingType: any;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  constructor(
    private walletService: WalletService,
    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService
  ) {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.isFilter = localStorage.getItem('filter');
    this.billingType = this.currentUserData?.project?.billingType;
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.sharedService.walletHistory$.subscribe((res) => {
      //this.walletDetails = res;
      if (res) {
        console.log(res)
        this.walletDetailsId = res;
      }
    });
    let getWalletHistoryResponse$ = this.store.pipe(
      select(getWalletHistoryResponse)
    );
    getWalletHistoryResponse$.subscribe((res: any) => {
      if (res) {
        console.log('res', res);
        this.getWalletHistoryList = [...res.items];
        this.wallet_historyPageSize = res.pageSize;
        this.totalwallethistoryCount = res.totalCount;
        this.countChange.emit(res.totalCount);
        console.log('wallets hisotyr list', this.getWalletHistoryList);
      }
      this.loadWalletHistory();
      console.log('wallets hisotyr list', res);
    });
  }
  isFilter: any
  ngOnInit(): void {
    const savedFilter = this.sharedService.getFilters('wallet History');
    if (this.isFilter == 'true' && savedFilter) {
      this.walletHisoryState.filter = savedFilter;
      this.walletQueryString = this.sharedService.createQuery(this.walletHisoryState);

    }
    console.log('wallets hisotyr list', this.walletDetails);
    // this.getAllWalletHistory();
    /*     if (this.isFilter == 'true') {
          this.filter = this.sharedService.getFilter();
          this.walletHisoryState.filter = this.filter;
          console.log(this.filter)
          console.log(this.walletHisoryState)
          this.walletQueryString = this.sharedService.createQuery(this.walletHisoryState);
        } */
    this.getWalletHistory();
  }
  ngOnDestroy() {
    this.store.dispatch(resetState());
  }
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.walletConfigPageChange({ skip: 0, take: this.wallet_historyPageSize })
      this.getWalletHistory()
    }
  }
  onSearchWalletHistory(): void {
    if (this.walletSearch.length > 0) {
      this.getWalletHistory()
    }
  }

  getAllWalletHistory(): void {
    this.store.dispatch(
      getAllWalletHistorylist({
        pageIndex: 1,
        pageSize: this.totalwallethistoryCount,
        searchTerm: this.walletSearch,
        filter: this.walletQueryString,
        orderBy: this.walletOrderByQuery,
        walletId: this.walletDetailsId,
      })
    );
  }
  getWalletHistory(): void {
    this.store.dispatch(
      getWalletHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.wallet_historyPageSize,
        searchTerm: this.walletSearch,
        filter: this.walletQueryString,
        orderBy: this.walletOrderByQuery,
        walletId: this.walletDetailsId,
      })
    );


  }

  private loadWalletHistory(): void {
    const state: State = {
      take: this.wallet_historyPageSize,
      sort: this.sort,
      filter: {
        logic: "and",
        filters: [
        ],
      } as CompositeFilterDescriptor,
    };
    const processedData = process(this.getWalletHistoryList, state);
    /*  const formattedProjectsList = this.getWalletHistoryList.map((item: any) => {
       return {
         ...item,
         paidDate: item.paidDate ? new Date(item.paidDate) : null
       };
     }); */
    // const processedData = process(formattedProjectsList, state);
    //const processedData = process(this.getWalletHistoryList, state);

    //  console.log(processedData)
    // console.log(processedData);
    this.walletHistorygridData = {
      data: processedData.data,
      total: this.totalwallethistoryCount,
    };
  }
  onWalletSortChange(sort: SortDescriptor[]): void {
    this.walletHisoryState.sort = sort;
    console.log(this.walletHisoryState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.walletHisoryState.sort
    );
    this.walletOrderByQuery = orderByQuery;

    this.getWalletHistory();
  }

  public customFilterState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  customDateFiltersQueryEq: any[] = [];
  customDateFiltersQueryNEQ: any[] = [];

  async onWalletHistoryFilterChange(filter: CompositeFilterDescriptor) {
    this.walletHisoryState.filter = filter;
    console.log(this.walletHisoryState);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilters('wallet History', filter);
    //this.sharedService.setFilter(filter);


    this.customFilterState = {
      skip: 0,
      take: 10,
      filter: {
        logic: 'and',
        filters: [],
      },
    };
    this.customFilterState = _.cloneDeep(this.walletHisoryState);
    var filterOperators = await this.sharedService.togetFilterOperators(this.walletHisoryState);
    console.log("temp-------------->", filterOperators)
    const iscustomdates = filterOperators?.filter((filter: any) => {
      return filter.value instanceof Date;
    });

    let isEq: any;
    let isNeq: any;
    this.customDateFiltersQueryEq = [];
    this.customDateFiltersQueryNEQ = [];

    if (iscustomdates && iscustomdates.length > 0) {
      isEq = iscustomdates.filter((filter: any) => (filter.operator === "eq"));
      isNeq = iscustomdates.filter((filter: any) => (filter.operator === "neq"))

      if (isEq.length > 0) {
        this.customDateFiltersQueryEq = this.sharedService.customizeFilterQueryEQ(isEq);
        console.log("gasQueryString", this.customDateFiltersQueryEq)
      } if (isNeq?.length > 0) {
        isNeq.forEach((item: any) => {
          this.customDateFiltersQueryNEQ = this.sharedService.customizeFilterQueryNEQ(isNeq);
          console.log("gasQueryString", this.customDateFiltersQueryNEQ)
        })

      }

    } else {
      isNeq = undefined;
      isEq = undefined;
      this.customDateFiltersQueryEq = [];
      this.customDateFiltersQueryNEQ = [];
    }
    if (this.customDateFiltersQueryEq.length > 0 || this.customDateFiltersQueryNEQ.length > 0) {
      let customFilterQueryString = await this.sharedService.newmodifyFilterJson(this.customFilterState, this.customDateFiltersQueryEq, this.customDateFiltersQueryNEQ);
      this.walletQueryString = this.sharedService.dateFiltercreateQuery(customFilterQueryString);
    } else {
      this.walletQueryString = this.sharedService.createQuery(this.customFilterState);
    }

    const queryString = this.sharedService.createQuery(this.walletHisoryState);
    /* this.walletQueryString = this.sharedService.createQuery(
      this.walletHisoryState
    ); */
    this.currentPage = 1
    this.getWalletHistory();
    console.log(queryString);
  }

  protected walletConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionWallet = [];
    this.skip = skip;
    this.wallet_historyPageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getWalletHistory();
    this.loadWalletHistory();
  }

  public walletHistoryselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionWallet.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionWallet.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionWallet.push(row.dataItem);
      }
    });

    console.log(this.mySelectionWallet);
  }

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionWallet.some((row: any) => row.id === rowArg.id);
  };
  protected walletDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadWalletHistory();
  }

  public onWalletDataSearchFilter(value: any): void {
    // const inputValue = value;
    // this.walletSearch = value;
    this.walletConfigPageChange({ skip: 0, take: this.wallet_historyPageSize })
    this.getWalletHistory();
  }
  fromDate: any[] = [];

  filterBasedOnDate(flag: any) {
    if (flag == 0) {
      this.fromDate = [];
      this.walletQueryString = '';
    } else {
      if (this.fromDate.length === 2) {
        const startDate = this.fromDate[0];
        const endDate = this.fromDate[1];

        if (startDate && endDate) {
          // Format the dates to yyyy-MM-dd
          const formattedStartDate = startDate.toISOString().split('T')[0];
          const formattedEndDate = endDate.toISOString().split('T')[0];

          // Construct the filter query
          this.walletQueryString = `$filter=(paidDate ge ${formattedStartDate} and paidDate le ${formattedEndDate})`;
        } else {
          console.log('Invalid date range');
        }
      } else {
        console.log('Invalid date selection');
      }
    }

    this.getWalletHistory();

  }




  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.walletQueryString = ''
    this.fromDate = [];// Clear the selected date
    this.getWalletHistory();
  }




  selectDates(event: any) {
    if (event == undefined) {
      this.fromDate = [];
      this.walletQueryString = '';
      this.getWalletHistory();
    }
  }

  billHistoryExport(): void {

    let data =
      this.mySelectionWallet.length > 0
        ? this.mySelectionWallet
        : this.walletHistorygridData.data;
    const workbook = XLSX.utils.book_new();
    const masterDetailData: any[] = [];
    if (data.length > 0) {
      data.forEach(
        (item: any) => {
          masterDetailData.push({
            'Date': formatDate(item.paidDate, 'dd MMM yyyy', 'en-US'),
            'Amount Deducted': item.totalAmountDeducted,
            "Opening Balance": item.openingBalance,
            "Closeing Balance": item.closingBalance,
            'Bill No': '',
            'Residential Unit': '',
            'Bill Date': '',
            'Payment Date': '',
            'utility': '',
            'Device Sr No': '',
            'Mac Address': '',
            'Bill Amount': '',
            'Mode Of Payment': '',
            'Payment Status': '',


          });

          item.walletDtls.forEach(
            (detail: any) => {
              masterDetailData.push({
                'Date': '',
                'Amount Deducted': '',
                "Opening Balance": '',
                "Closeing Balance": '',
                'Bill No': detail.billNo,
                'Residential Unit': detail.residentialUnit,
                'Bill Date': formatDate(detail.billDate, 'dd MMM yyyy', 'en-US'),
                'Payment Date': formatDate(detail.paymentDate, 'dd MMM yyyy', 'en-US'),
                'utility': detail.utility,
                'Device Sr No': detail.meterNo,
                'Mac Address': detail.macAddress,
                'Bill Aamount': detail.totalAmount,
                'Mode Of Payment': detail.paymentMode,
                'Payment Status': detail.paymentStatus,
              });
            }
          );
        }
      );
      console.log("masterDetailData--------->", masterDetailData)
      const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

      });

      const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
        if (cell) {
          cell.s = { font: { bold: true } };
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        'WalletHistory.xlsx'
      );
    } else {
      this.sharedService.showMessageDialog(
        'No data to display',
        '',
        SweetAlertIcon.ERROR
      );
    }
  }
  ConsumptionExport(): void {
    let data =
      this.mySelectionWallet.length > 0
        ? this.mySelectionWallet
        : this.walletHistorygridData.data;
    if (data.length > 0) {
      console.log(data)
      const workbook = XLSX.utils.book_new();
      const masterDetailData: any[] = [];

      data.forEach(
        (item: any) => {
          masterDetailData.push({
            'Date': item.paidDate ? formatDate(item.paidDate, 'dd MMM yyyy', 'en-US') : '',
            // 'Date': formatDate(item.paidDate, 'DD/MM/YYYY', 'en-US'),
            'Amount Deducted': item.totalAmountDeducted,
            "Opening Balance": item.openingBalance,
            "Closeing Balance": item.closingBalance,
            //'Residential Unit': '',
            'Utility': '',
            'Device sr No': '',
            'Mac Address': '',
            'Consumption': '',
            'Consumption Unit EB': '',
            'Consumption Unit DG': '',
            'UnitRate': '',
            'UnitRate EB': '',
            'UnitRate DG': '',
            'Available Balance': '',
            'Initial  Reading': '',
            'Final  Reading': '',
            'Initial MeterReading EB': '',
            'Initial Meter Reading DG': '',
            'Final MeterReading EB': '',
            'Final Meter Reading DG': '',
            'Initial Reading Date': '',
            'Final Reading Date': '',
            'Service Name': '',
            'Gst Amount': '',
            'Gst percentage': '',
          });


          item.walletDtls.forEach(
            (detail: any) => {
              masterDetailData.push({
                'Date': '',
                'Amount Deducted': detail.totalAmountDeducted,
                // 'Residential Unit': detail.residentialUnit,
                'Utility': detail.utility,
                'Device sr No': detail.meterNo,
                'Mac Address': detail.macAddress,
                'Consumption': detail.consumptionUnits,
                'Consumption Unit EB': detail.consumptionUnitsEB != undefined ? detail.consumptionUnitsEB : '',
                'Consumption Unit DG': detail.consumptionUnitsDG != undefined ? detail.consumptionUnitsDG : '',
                'UnitRate': detail.unitRate != undefined ? detail.unitRate : '',
                'UnitRate EB': detail.utility == 'Electricity' ? detail.unitRateEB != undefined ? detail.unitRateEB : "" : '-',
                'UnitRate DG': detail.utility == 'Electricity' ? detail.unitRateDG != undefined ? detail.unitRateDG : '' : '-',
                'Available Balance': detail.availableBalance,
                'Initial  Reading': detail.initialMeterReading,
                'Final  Reading': detail.finalMeterReading,
                'Initial MeterReading EB': detail.initialMeterReadingEB,
                'Initial Meter Reading DG': detail.initialMeterReadingDG,
                'Final MeterReading EB': detail.finalMeterReadingEB,
                'Final Meter Reading DG': detail.finalMeterReadingDG,
                'Initial Reading Date': formatDate(detail.previousReadingDate, 'dd MMM yyyy', 'en-US'),
                'Final Reading Date': formatDate(detail.finalReadingDate, 'dd MMM yyyy', 'en-US'),
                'Service Name': detail.serviceName,
                'Gst Amount': detail.gstAmount,
                'Gst percentage': detail.gstPer,

              });

            }
          );
        }
      );
      console.log(masterDetailData)
      const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

      });

      const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
        if (cell) {
          cell.s = { font: { bold: true } };
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        'WalletHistory.xlsx'
      );
    } else {
      this.sharedService.showMessageDialog(
        'No data to display',
        '',
        SweetAlertIcon.ERROR
      );
    }
  }

  public onExcelExport2(args: ExcelExportEvent): void {
    args.preventDefault();

    const workbook = args.workbook;
    const sheet = workbook.sheets[0];
    const rows = sheet.rows;
    const data = this.walletHistorygridData.data;

    let rowOffset = 0;

    // Iterate through each master record
    data.forEach((masterRecord) => {
      // Add master record row
      rows.splice(rowOffset, 0, {
        cells: [
          { value: 'Date: ' + masterRecord.paidDate },
          { value: 'Amount: ' + masterRecord.amountDeducted },
        ],
      });
      rowOffset++;

      // Add header row for details
      rows.splice(rowOffset, 0, {
        cells: [
          { value: 'Residential Unit' },
          { value: 'Bill No' },
          { value: 'Utility' },
          { value: 'Meter No' },
          { value: 'Consumption' },
          { value: 'Unit Rate' },
          { value: 'Unit Rate EB' },
          { value: 'Unit Rate DG' },
          { value: 'Available Balance' },
        ],
      });
      rowOffset++;

      // Add detail rows
      masterRecord.walletDtls.forEach(
        (detail: {
          residentialUnit: any;
          billNo: any;
          utility: any;
          meterNo: any;
          consumption: any;
          unitRate: any;
          unitRateEB: any;
          unitRateDG: any;
          availableBalance: any;
        }) => {
          rows.splice(rowOffset, 0, {
            cells: [
              { value: detail.residentialUnit },
              { value: detail.billNo },
              { value: detail.utility },
              { value: detail.meterNo },
              { value: detail.consumption },
              { value: detail.unitRate },
              { value: detail.unitRateEB },
              { value: detail.unitRateDG },
              { value: detail.availableBalance },
            ],
          });
          rowOffset++;
        }
      );

      // Add a blank row to separate groups (optional)
      rows.splice(rowOffset, 0, { cells: [{ value: '' }] });
      rowOffset++;
    });

    // Trigger download
    args.workbook.toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, 'WalletHistory.xlsx');
    });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  preventManualInput(event: KeyboardEvent): void {
    // Prevent all keys except Tab and Arrow keys, which are essential for navigating
    const allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent input
    }
  }








}
