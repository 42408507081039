import { createAction, props } from '@ngrx/store';
import { billModel, invalidaBills } from './bills.model';

// fetch  bills
export const getBillslist = createAction(
  '[Bills] bills',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    billType: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getBilsSuccess = createAction(
  '[Bills] fetch bills success',
  props<{ getBillsResponse: any }>()
);
export const getBillsFailure = createAction(
  '[Bills fetch bills failed]',
  props<{ getBillsError: any }>()
);

// fetch  bills Export
export const getBillsListExport = createAction(
  '[Bills ] bills Export',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    billType: string;
    filter?: string;
    orderBy?: string;
    criteria?: string,
  }>()
);
export const getBilsExportSuccess = createAction(
  '[Bills] fetch bills Export success',
  props<{ getBillsExportResponse: any }>()
);
export const getBillsExportFailure = createAction(
  '[Bills fetch bills Export failed]',
  props<{ getBillsExportError: any }>()
);

// fetch  by id  bills
export const getBillsByIdlist = createAction(
  '[Bills ] Bills ById',
  props<{ id: string }>()
);
export const getBillsByIdSuccess = createAction(
  '[Bills ] fetch Bills  By id success',
  props<{ getBillsByIdResponse: any }>()
);
export const getBillsByIdFailure = createAction(
  '[Bills ] fetch Bills  By Id failed]',
  props<{ getBillsByIdError: any }>()
);

export const resetgetBillsByIdlist = createAction('[get billsbyId State ] Reset State');


// fetch  residential unit by Id
export const getResidentiallist = createAction(
  '[Residentaial ] Residentaial List',
  props<{ projectId: string, billType: string }>()
);
export const getResidentialSuccess = createAction(
  '[Residentaial ] Residentaial  success',
  props<{ getresidentialResponse: any }>()
);
export const getresidentialFailure = createAction(
  '[Residentaial ] Residentaial  failed]',
  props<{ getResidentialError: any }>()
);

// fetch  rget-BillReadingsInfo
export const getBillReadingsInfoList = createAction(
  '[BillReadingsInfo ] BillReadingsInfo List',
  props<{ id: any; projectId: string; MacAddress: string }>()
);
export const getBillReadingsInfoSuccess = createAction(
  '[BillReadingsInfo ] BillReadingsInfo  success',
  props<{ getBillReadingsInfoResponse: any }>()
);
export const getBillReadingsInfoFailure = createAction(
  '[BillReadingsInfo ] BillReadingsInfo  failed]',
  props<{ getBillReadingsInfoError: any }>()
);

export const resetBillReadingsInfoState = createAction(
  '[Residential Unit] Reset State'
);

// fetch  get-Bill Deatils by month
export const getBillReadingsByMonthList = createAction(
  '[BillReadingsInfo  ] BillReadingsInfo ByMonth List',
  props<{
    id: any;
    projectId: string;
    BillMonth: string;
    MacAddress: any;
    IsCombined: boolean;
  }>()
);
export const getBillReadingsByMonthSuccess = createAction(
  '[BillReadingsInfo ] BillReadingsInfo  ByMonth success',
  props<{ getBillReadingsByMonthResponse: any }>()
);
export const getBillReadingsByMonthFailure = createAction(
  '[BillReadingsInfo ] BillReadingsInfo  ByMonth failed]',
  props<{ getBillReadingsByMonthError: any }>()
);

// fetch  get-Bill Deatils by month to Adhoc Bill
export const getBillReadingsByMonthAdhocList = createAction(
  '[BillReadingsInfo Adhoc ] BillReadingsInfo ByMonth Adhoc List',
  props<{ adhocBills: any }>()
);
export const getBillReadingsByMonthAdhocSuccess = createAction(
  '[BillReadingsInfo Adhoc] BillReadingsInfo  ByMonth Adhoc success',
  props<{ getBillReadingsByMonthAdhocResponse: any }>()
);
export const getBillReadingsByMonthAdhocFailure = createAction(
  '[BillReadingsInfo Adhoc] BillReadingsInfo  ByMonth  Adhoc failed]',
  props<{ getBillReadingsByMonthAdhocError: any }>()
);

// Create marka as Invalid Bills
export const createinvildBills = createAction(
  '[invildBills] invildBills',
  props<{ payload: any }>()
);
export const addinvildBillsSuccess = createAction(
  '[invildBills] invildBills Success',
  props<{ createinvildBillsResponse: any }>()
);
export const addinvildBillsFailure = createAction(
  '[invildBills] invildBills Failure',
  props<{ createinvildBillsError: any }>()
);

// Create User List
export const createBills = createAction(
  '[Bills] Bills',
  props<{ bilss: billModel }>()
);
export const addBillsSuccess = createAction(
  '[Bills]Bills Success',
  props<{ createBillsResponse: any }>()
);
export const addBillsFailure = createAction(
  '[Bills] Bills Failure',
  props<{ createBillsError: any }>()
);

// payBycash Modde
export const Paycashbill = createAction(
  '[Paycashbill] Paycashbill',
  props<{ bilss: any }>()
);
export const PaycashbillSuccess = createAction(
  '[Paycashbill]Paycashb ill Success',
  props<{ PaycashbillSuccessResponse: any }>()
);
export const PaycashbillFailure = createAction(
  '[Paycashbill] Paycash bill Failure',
  props<{ PaycashbillFailureError: any }>()
);

// fetch  manual Bills
export const getManuallist = createAction(
  '[Bills] getMaster',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    billType: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getManualSuccess = createAction(
  '[Bills] fetch bills success',
  props<{ getManualResponse: any }>()
);
export const getManualFailure = createAction(
  '[Bills fetch bills failed]',
  props<{ getManualError: any }>()
);

// fetch  manual Bills  Export
export const getManuallistExport = createAction(
  '[Bills Export] manual Export',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    billType: string;
    filter?: string;
    orderBy?: string;
    criteria?: string,
  }>()
);
export const getManualExportSuccess = createAction(
  '[Bills] fetch bills Export success',
  props<{ getManualExportResponse: any }>()
);
export const getManualExportFailure = createAction(
  '[Bills fetch bills  Export failed]',
  props<{ getManualExportError: any }>()
);

//create bills
export const createManualBills = createAction(
  '[manualBills] CreategeManualBills ',
  props<{ manualBills: billModel }>()
);
export const createManualBillsSuccess = createAction(
  '[manualBills] Manual Success',
  props<{ createManualBillsResponse: any }>()
);
export const createManualBillsFailure = createAction(
  '[manualBills] Manual Failure',
  props<{ createManualBillsError: any }>()
);

//create Adhoc Billls
export const createAdhoc = createAction(
  '[Adhoc] CreategetAdhoc',
  props<{ gateway: billModel }>()
);
export const createAdhocSuccess = createAction(
  '[Adhoc] Adhoc Success',
  props<{ createAdhocResponse: any }>()
);
export const createAdhocFailure = createAction(
  '[Adhoc] Adhoc Failure',
  props<{ createAdhocError: any }>()
);

// fetch  Adhoc Bills
export const getAdhoclist = createAction(
  '[Adhoc Bills ] getMaster',
  props<{
    projectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    billType: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAdhocSuccess = createAction(
  '[ Adhoc Bills] fetch Adhoc success',
  props<{ getAdhoctResponse: any }>()
);
export const getAdhocFailure = createAction(
  '[ AdhocBills ]fetch Adhoc failed]',
  props<{ getAdhocError: any }>()
);

//adhoc Export
export const getAdhoclistExport = createAction(
  '[Adhoc Bills Export] adhoc Export',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    billType: string;
    filter?: string;
    orderBy?: string;
    criteria?: string,
  }>()
);
export const getAdhocExpoertSuccess = createAction(
  '[ Adhoc Bills] fetch Adhoc export success',
  props<{ getAdhoctExportResponse: any }>()
);
export const getAdhocExpoertFailure = createAction(
  '[ AdhocBills ]fetch Adhoc export failed ]',
  props<{ getAdhocExportError: any }>()
);


// bill Invoice 

export const getBillsInvoiceExport = createAction('[Bills  Invoice Export] Invoice Export', props<{ ProjectId: string; criteria?: string, IsConsumer: boolean, }>())
export const getBillsInvoiceExportSuccess = createAction('[Bills Invoice] fetch Invoice bills Export success', props<{ getBillsInvoiceExportResponse: any }>())
export const getBillsInvoiceExportFailure = createAction('[Bills Invoice] fetch Invoice bills  Export failed]', props<{ getBillsInvoiceExportError: any }>())

export const getBillsInvoice = createAction(
  '[getBilllsInvoice] getBilllsInvoice',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
    IsConsumer: boolean
  }>()
);

export const getBillsInvoiceSuccess = createAction('[getBillsInvoice] getBillsInvoice Success', props<{ getBillsInvoiceResponse: any }>());
export const getBillsInvoiceFailure = createAction('[getBillsInvoice] getBillsInvoice Failure', props<{ getBillsInvoiceError: any }>());


export const resetbillsInfoState = createAction('[bills Info State ] Reset Info State');


export const resetbillsInvoiceExportState = createAction('[bills State ] Reset State');

export const resetbillsInvoiceState = createAction('[bills State ] Reset State');

// reset  bills store data
export const resetbillsState = createAction('[bills State ] Reset State');
// reset  bills store data
export const resetbillsAdhocState = createAction('[bills State ] Reset State');
// reset  bills store data
export const resetbillsManualState = createAction('[bills State ] Reset State');


