import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { eleGridCoumns, SweetAlertIcon, UtilityType, wterGridCoumns } from '../../../shared/constants/enum';
import {
  process,
  State,
  toODataString,
  SortDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';


import { Store, select } from '@ngrx/store';
import { GetElectricityRateData, GetGasRateData, GetWaterRateData } from '../../../store/Utilities/utilities.actions';
import { gasGridCoumns } from '../../constants/enum';
import { DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { table_pageSize } from '../../constants/templatesNames';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SharedService } from '../../shared.service';
import _ from 'lodash';
import { LoaderService } from '../../../core/services/loader.service';
import { getElectricityRateDataResponse, getGasRateDataResponse, getWaterRateDataResponse } from '../../../store/Utilities/utilities.selector';
import { Observable, Subscription } from 'rxjs';

import { SVGIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { Helper } from '../../../core/helpers/helper';




@Component({
  selector: 'app-unit-rate-history',
  templateUrl: './unit-rate-history.component.html',
  styleUrl: './unit-rate-history.component.scss'
})
export class UnitRateHistoryComponent {
  currentUser: any;
  currentprojectUtilities: any[] = [];
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  SearchedValue: string = '';
  public gasPageSize = 10;

  public skip = 0;
  public waterskip = 0;
  public electricskip = 0;
  public evskip = 0;
  totalGasCount: number = 0;
  totalWaterCount: number = 0;
  totalElectricCount: number = 0;
  currentPage: number = 1;
  waterCurrentPage: number = 1;
  electricCurrentPage: number = 1;
  evCurrentPage: number = 1;
  projectId: any;
  public previousNext = true;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public gasGridData: GridDataResult = { data: [], total: 0 };
  waterGridData: GridDataResult = { data: [], total: 0 };
  ElectricityGridData: GridDataResult = { data: [], total: 0 };
  EvGridData: GridDataResult = { data: [], total: 0 };

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  public State: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  private getGasRateDataResponse$: Subscription = new Subscription();
  private getElectricityRateDataResponse$: Subscription = new Subscription();
  private getWaterRateDataResponse$: Subscription = new Subscription();
  gasDataList: any[] = [];

  public gassort: SortDescriptor[] = [];

  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    checkboxOnly: true,
  };

  gasGridColumns = gasGridCoumns;
  waterGridColumns = wterGridCoumns
  eleGridColumns = eleGridCoumns
  @Input() gridColumns: any;
  @Input() unitRateGridData: any;
  @Input() totalCount: any;
  @Input() pageSize: any;
  @Input() selectedUtility: any;
  gasOrderByQuery: string = '';
  gasQueryString: string = '';
  // gasSearchedValue: any = '';
  utilityType = UtilityType;

  columnMap: any


  private previousUnitRateGridData: any;
  private previousSelectedUtility: any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;

  constructor(private store: Store, private sharedService: SharedService, private loaderService: LoaderService,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.currentprojectUtilities = this.currentUser.projectUtilities;
    this.projectId = this.currentUser?.project.id;
    console.log(this.currentprojectUtilities)

  }

  /*   ngAfterViewInit() {
      console.log(this.unitRateGridData)
    }
    ngAfterContentInit() {
      console.log(this.unitRateGridData)
    } */
  ngOnChanges(changes: SimpleChanges) {

    // if (changes['unitRateGridData']) {
    // console.log('unitRateGridData changed:', this.unitRateGridData);
    // console.log(this.totalCount)
    // console.log(this.selectedUtility);

    this.columnMap = {
      [this.utilityType.GAS]: this.gasGridColumns,
      [this.utilityType.ELECTRICITY]: this.eleGridColumns,
      [this.utilityType.WATER]: this.waterGridColumns,
      [this.utilityType.TriSource]: this.eleGridColumns,
    };
    this.gridColumns = this.columnMap[this.selectedUtility] || '';

    // this.gridColumns = this.selectedUtility == this.utilityType.GAS ? this.gasGridCoumns : this.utilityType.ELECTRICITY ? this.eleGridCoumns : this.utilityType.WATER ? this.waterGriCdoumns : this.utilityType.TriSource ? this.eleGridCoumns : ''
    //  this.gasGridData = this.unitRateGridData
    this.loadGasConfig(this.unitRateGridData);

    console.log(this.gridColumns)
    // }

  }

  ngOnInit(): void {
    this.loaderService.setLoading(true);
    //console.log(this.unitRateGridData)

  }

  private loadGasConfig(gridData: any): void {

    const state: State = {
      take: this.pageSize,
      sort: this.gassort,
      filter: {
        logic: "and",
        filters: [
        ],
      } as CompositeFilterDescriptor,  // Providing a valid filter
    };
    //  console.log(this.totalCount)

    const processedData = process(gridData, state);
    console.log(processedData)
    this.gasGridData = {
      data: processedData.data,
      total: this.totalCount,
    };
    console.log(this.gasGridData)
    this.loaderService.setLoading(false);

  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  public onValueChange(event: string): void {
    if (event.length == 0) {
      this.gasUnitRatePageChange({ skip: 0, take: 10 })
      this.trigerApiByUtility(this.selectedUtility)
    }
  }


  public onMeterConfigFilter(value: any): void {
    this.gasUnitRatePageChange({ skip: 0, take: 10 })
    this.trigerApiByUtility(this.selectedUtility)
  }

  onSearchGasUnits(): void {
    if (this.SearchedValue.length > 0) {
      this.trigerApiByUtility(this.selectedUtility)
    }
  }



  trigerApiByUtility(utility: any) {
    const utilityMasterId = this.currentprojectUtilities.find((u: any) => u.name === utility)?.id;
    console.log(utilityMasterId)

    console.log(utility)
    switch (utility) {
      case this.utilityType.GAS:
        this.store.dispatch(
          GetGasRateData({
            pageIndex: this.currentPage,
            pageSize: this.gasPageSize,
            searchTerm: this.SearchedValue,
            filter: this.gasQueryString,
            orderBy: this.gasOrderByQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;

      // Add other cases here as needed
      case this.utilityType.ELECTRICITY:
        console.log(utilityMasterId)

        this.store.dispatch(
          GetElectricityRateData({
            pageIndex: this.currentPage,
            pageSize: this.gasPageSize,
            searchTerm: this.SearchedValue,
            filter: this.gasQueryString,
            orderBy: this.gasOrderByQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break;
      case this.utilityType.TriSource:
        console.log(utilityMasterId)

        this.store.dispatch(
          GetElectricityRateData({
            pageIndex: this.currentPage,
            pageSize: this.gasPageSize,
            searchTerm: this.SearchedValue,
            filter: this.gasQueryString,
            orderBy: this.gasOrderByQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break

      case this.utilityType.WATER:
        console.log(utilityMasterId)
        this.store.dispatch(
          GetWaterRateData({
            pageIndex: this.currentPage,
            pageSize: this.gasPageSize,
            searchTerm: this.SearchedValue,
            filter: this.gasQueryString,
            orderBy: this.gasOrderByQuery,
            projectId: this.projectId,
            utilityMasterId: utilityMasterId,
          })
        );
        break
      default:
        break

    }

  }
  customDateFiltersQueryEq: any[] = [];
  customDateFiltersQueryNEQ: any[] = [];
  public customFilterState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  async onGasRateFilterChange(filter: CompositeFilterDescriptor) {
    this.customFilterState = {
      skip: 0,
      take: 10,
      filter: {
        logic: 'and',
        filters: [],
      },
    };
    this.skip = 0

    this.currentPage = 1
    this.State.filter = filter;
    console.log("filter-->", this.State);
    console.log(filter)
    // console.log("filter-->", this.gasState);
    this.customFilterState = _.cloneDeep(this.State);
    var filterOperators = await this.sharedService.togetFilterOperators(this.State);
    //console.log("temp-------------->", filterOperators)


    const iscustomdates = filterOperators?.filter((filter: any) => {
      return filter.value instanceof Date;
    });

    let isEq: any;
    let isNeq: any;
    this.customDateFiltersQueryEq = [];
    this.customDateFiltersQueryNEQ = []
    if (iscustomdates && iscustomdates.length > 0) {
      isEq = iscustomdates.filter((filter: any) => (filter.operator === "eq"));
      isNeq = iscustomdates.filter((filter: any) => (filter.operator === "neq"))

      // console.log("IsEq", isEq)
      // console.log("isNeq", isNeq)

      if (isEq.length > 0) {
        this.customDateFiltersQueryEq = this.sharedService.customizeFilterQueryEQ(isEq);
        console.log("gasQueryString", this.customDateFiltersQueryEq)
      } if (isNeq?.length > 0) {
        isNeq.forEach((item: any) => {
          this.customDateFiltersQueryNEQ = this.sharedService.customizeFilterQueryNEQ(isNeq);
          // console.log("gasQueryString", this.customDateFiltersQueryNEQ)
        })

      }

    } else {
      isNeq = undefined;
      isEq = undefined;
      this.customDateFiltersQueryEq = [];
      this.customDateFiltersQueryNEQ = [];
    }
    // console.log("gasQueryString", this.customDateFiltersQueryEq)
    if (this.customDateFiltersQueryEq.length > 0 || this.customDateFiltersQueryNEQ.length > 0) {
      console.log(this.customFilterState)
      let customFilterQueryString = await this.sharedService.newmodifyFilterJson(this.customFilterState, this.customDateFiltersQueryEq, this.customDateFiltersQueryNEQ);
      console.log(customFilterQueryString);
      this.gasQueryString = this.sharedService.dateFiltercreateQuery(customFilterQueryString);
    } else {
      this.gasQueryString = this.sharedService.createQuery(this.customFilterState);
    }
    //console.log(this.selectedUtility)
    this.trigerApiByUtility(this.selectedUtility)
  }


  onGasSortChange(sort: SortDescriptor[]): void {
    if (this.selectedUtility == UtilityType.GAS) {
      this.State.sort = sort;
      console.log(this.State.sort);
      const gasOrderByQuery = this.sharedService.createOrderByQuery(
        this.State.sort
      );
      this.gasOrderByQuery = gasOrderByQuery;
      this.trigerApiByUtility(this.selectedUtility)
    }
  }



  mySelectionGas: any = [];
  protected gasUnitRatePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionGas = [];
    this.skip = skip;
    this.gasPageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.trigerApiByUtility(this.selectedUtility);

  }

  public gasSelectionChanged(selection: any) {
    console.log(selection)
    // if (this.selectedUtility == UtilityType.GAS) {
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionGas.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionGas.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isGasRowSelected(row.dataItem)) {
        // Create a shallow copy of the dataItem to avoid direct modification
        const updatedRow = { ...row.dataItem };

        // Convert date fields to a more readable format
        updatedRow.fromDate = `${Helper.formatDate(updatedRow.fromDate)}`;
        updatedRow.toDate = updatedRow.toDate !== null ? `${Helper.formatDate(updatedRow.toDate)}` : 'Till now';

        // Add the updated row to the selection list
        this.mySelectionGas.push(updatedRow);
        console.log(this.mySelectionGas);
      }
    });


    // }
  }
  public isGasRowSelected = (rowArg: any): boolean => {
    return this.mySelectionGas.some((row: any) => row.id === rowArg.id);


  };
  exportAllData: any[] = []
  public onExport(type: string) {
    this.hiddenGrid?.saveAsExcel();
    if (this.mySelectionGas.length == 0) {
      this.exportAllData = this.gasGridData.data
      this.exportAllData.map((item: any) => {
        return {
          ...item,
          fromDate: `${Helper.formatDate(item.fromDate)}`,
          toDate: item.toDate !== null ? `${Helper.formatDate(item.toDate)}` : 'Till now'
        }
      })

    }





  }
  downloadFile(file: string, fileName: string) {
    this.sharedService.downloadUtilityFiles(file, fileName);
  }

  protected gasDataStateChange(state: DataStateChangeEvent): void {
    console.log(state)

    this.sort = state.sort || [];
    this.gasPageSize = state.take;

    this.filter = state.filter || { logic: 'and', filters: [] };

  }




}
