<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="">
                    <!-- <h4 class="card-title mb-4"></h4> -->
                    <div>
                        <div>
                            <ng-template #template let-anchor>
                                <span>{{ anchor.nativeElement.innerText }}</span>
                            </ng-template>
                            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                (mouseover)="showTooltip($event)">
                                <kendo-grid [data]="gasGridData" [selectable]="selectableSettings" [pageSize]="pageSize"
                                    [skip]="skip" [sortable]="true" [pageable]="{
                                   buttonCount: buttonCount,
                                             info: info,
                                       type: type,
                                     pageSizes: pageSizes,
                                   previousNext: previousNext
                                    }" [columnMenu]="{ filter: true }" [reorderable]="true" filterable="menu"
                                    [filter]="State.filter" [sort]="State.sort"
                                    (filterChange)="onGasRateFilterChange($event)"
                                    (sortChange)="onGasSortChange($event)" [resizable]="true" scrollable="scrollable"
                                    (pageChange)="gasUnitRatePageChange($event)"
                                    (selectionChange)="gasSelectionChanged($event)"
                                    (dataStateChange)="gasDataStateChange($event)">

                                    <ng-template kendoGridToolbarTemplate>
                                        <div class="search_icon">
                                            <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                                [(ngModel)]="SearchedValue" [clearButton]="true"
                                                (valueChange)="onValueChange($event)"
                                                (keydown.enter)="onSearchGasUnits()"
                                                placeholder="Search in all columns...">
                                            </kendo-textbox>

                                            <button class="btn btn-primary btn-md border-left-none"
                                                (click)="onMeterConfigFilter($event)">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                        <kendo-grid-spacer></kendo-grid-spacer>
                                        <div class="sm-device d-flex justify-content-between">
                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="onExport('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>
                                        </div>
                                    </ng-template>

                                    <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                        class="no-tooltip"></kendo-grid-checkbox-column>


                                    <ng-container *ngFor="let column of gridColumns">

                                        <!-- Conditionally apply date filter for 'fromDate' and 'toDate' columns -->
                                        <kendo-grid-column [field]="column.field" [title]="column.title"
                                            [filter]="column.field === 'fromDate' || column.field === 'toDate' ? 'date' :
                                           column.field === 'minValue' || column.field === 'maxValue' ||  column.field === 'gasValue' || column.field === 'unitLPGValue' || column.field === 'unitPrice' ? 'numeric' : 'text'">

                                            <!-- Custom template for date fields -->
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <!-- Check if the field is 'toDate' and apply formatting -->
                                                <span *ngIf="column.field === 'toDate'">
                                                    {{ dataItem.toDate ? (dataItem.toDate | date: 'dd MMM yyyy')
                                                    : 'Till now' }}

                                                </span>

                                                <!-- Check if the field is 'fromDate' and apply formatting -->
                                                <span *ngIf="column.field === 'fromDate'">
                                                    {{ dataItem.fromDate | date: 'dd MMM yyyy' }}
                                                </span>

                                                <!-- For all other columns, simply display the value -->
                                                <span *ngIf="column.field !== 'toDate' && column.field !== 'fromDate'">
                                                    {{ dataItem[column.field] }}
                                                </span>
                                            </ng-template>

                                        </kendo-grid-column>

                                    </ng-container>
                                    <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <!-- Info Icon (Clickable) -->
                                            <div class="d-flex">
                                                <span *ngIf="dataItem?.fileUrl" kendoTooltip title="Download Document"
                                                    class="ms-2"
                                                    (click)="downloadFile(dataItem.fileUrl, 'Electricity Document')"
                                                    style="cursor: pointer; font-size: 18px; pointer-events: auto;">
                                                    <!-- Ensure custom tooltip works -->
                                                    <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </ng-template>
                                    </kendo-grid-command-column>


                                </kendo-grid>

                                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                    <kendo-grid
                                        [kendoGridBinding]="this.mySelectionGas.length > 0 ? this.mySelectionGas : exportAllData"
                                        #pdfGrid>
                                        <ng-container *ngFor="let column of gridColumns">
                                            <kendo-grid-column [field]="column.field" [title]="column.title">
                                            </kendo-grid-column>

                                        </ng-container>
                                        <kendo-grid-excel fileName="{{selectedUtility}}.xlsx"></kendo-grid-excel>
                                    </kendo-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>