<div class="container-fluid">

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>

                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="metergridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [selectable]="true" [selectable]="selectableSettings"
                            [pageable]="{ buttonCount: buttonCount, info: info, type: type,pageSizes: pageSizes, previousNext: previousNext}"
                            [reorderable]="true" filterable="menu" [filter]="meterViewState.filter"
                            [sort]="meterViewState.sort" [columnMenu]="{ filter: true }"
                            (filterChange)="onprojectFilterChange($event)" (sortChange)="onSortChange($event)"
                            [resizable]="true" scrollable="scrollable" (pageChange)="meterConfigPageChange($event)"
                            (selectionChange)="selectionChanged($event)" (dataStateChange)="dataStateChange($event)">

                            <!-- (dataStateChange)="dataStateChange($event)" -->
                            <ng-template kendoGridToolbarTemplate>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue"
                                    (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)"
                                        (keydown.enter)="onSearchMeterReadingUnits()" [style.height.px]="35"
                                        [(ngModel)]="searchedValue" placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none"
                                        (click)="onMeterConfigFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <kendo-grid-spacer></kendo-grid-spacer>
                                <div class="sm-device d-flex justify-content-between">

                                    <!-- <button kendoGridPDFCommand class="btn btn-light me-2" (click)="onMeterPdfExport()">
                                                                                <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->
                                    <button class="btn btn-outline-primary btn-sm " (click)="onExport('Excel')">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                    </button>
                                </div>

                            </ng-template>
                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="deviceSRNo" title="Device Sr.No"></kendo-grid-column>


                            <kendo-grid-column field="macAddress" [hidden]="true"
                                title="Mac Address"></kendo-grid-column>
                            <kendo-grid-column field="utilityType" title="Utility" [width]="100">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="currentDate" title="Time Stamp" filter="date">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.currentDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template></kendo-grid-column>

                            <kendo-grid-column field="meterReading" title="Meter Reading" filter="numeric"
                                filter="numeric"
                                *ngIf="(role == listOfRoles.ADMIN && !(meterViewDetails?.utility =='Electricity' || meterViewDetails?.utility =='TriSource'))">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.utilityType=='Water'?dataItem.meterReading+' L':
                                    dataItem.utilityType=='Electricity'?
                                    dataItem.meterReading+' kWh':
                                    dataItem.utilityType=='Gas'?dataItem.meterReading+' m³':''
                                    }}
                                </ng-template>

                            </kendo-grid-column>
                            <kendo-grid-column field="meterReadingEB" title="EB Meter Reading " filter="numeric"
                                *ngIf="(role == listOfRoles.ADMIN && (meterViewDetails?.utility =='Electricity' || meterViewDetails?.utility =='TriSource')) || (role == listOfRoles.CONSUMER)"
                                filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>

                                    {{dataItem.meterReadingEB !=undefined?dataItem.meterReadingEB+' kWh':'-'}}
                                </ng-template></kendo-grid-column>

                            <kendo-grid-column
                                *ngIf="(role == listOfRoles.ADMIN && (meterViewDetails?.utility =='Electricity' || meterViewDetails?.utility =='TriSource')) || (role == listOfRoles.CONSUMER)"
                                field="meterReadingDG" title="DG Meter Reading " filter="numeric" filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.meterReadingDG !=undefined?dataItem.meterReadingDG+' kWh':'-'}}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column
                                *ngIf="(role == listOfRoles.ADMIN && meterViewDetails?.utility =='TriSource') || (role == listOfRoles.CONSUMER)"
                                field="meterReadingTS" title="Solar Meter Reading " filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.meterReadingTS !=undefined?dataItem.meterReadingTS+' kWh':'-'}}
                                </ng-template>
                            </kendo-grid-column>


                            <kendo-grid-column field="onOffStatus" [hidden]="true" title="On/Off Status"
                                filter="numeric"
                                *ngIf="(role == listOfRoles.ADMIN && meterViewDetails?.utility.toLowerCase() =='gas') || (role == listOfRoles.CONSUMER && meterViewDetails?.utility.toLowerCase() =='gas')">
                            </kendo-grid-column>

                            <kendo-grid-column field="batteryLevel" title="Battery Level" [width]="100"
                                *ngIf="(role == listOfRoles.ADMIN && !(meterViewDetails?.utility =='Electricity' || meterViewDetails?.utility =='TriSource'))">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{
                                    dataItem.utilityType == 'Water' ? dataItem.batteryLevel :
                                    dataItem.utilityType == 'Electricity' ? '-' :
                                    dataItem.utilityType == 'TriSource' ? '-' :
                                    dataItem.utilityType == 'Gas' ? dataItem.batteryLevel : ''
                                    }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="batteryVoltage" [hidden]="true" filter="numeric"
                                title="Battery Voltage" [width]="100"
                                *ngIf="(role == listOfRoles.ADMIN && meterViewDetails?.utility !=='Electricity')">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{
                                    dataItem.utilityType == 'Water' ? dataItem.batteryVoltage :
                                    dataItem.utilityType == 'Electricity' ? '-' :
                                    dataItem.utilityType == 'Gas' ? dataItem.batteryVoltage :
                                    dataItem.utilityType == 'TriSource' ? dataItem.batteryVoltage : ''
                                    }}
                                </ng-template>

                            </kendo-grid-column>
                            <!-- <kendo-grid-column field="statusBits" title="Status Bit"></kendo-grid-column> -->
                            <kendo-grid-column field="occupant" title="Occupant"></kendo-grid-column>


                        </kendo-grid>
                    </div>
                    <!--master download buttons start-->
                    <kendo-excelexport [data]="selectedRowdataOnOff.length>0 ? selectedRowdataOnOff:metergridData.data"
                        fileName="MeterConfig.xlsx" #meterexcelexport>
                        <kendo-excelexport-column field="currentDate" title="Time Stamp"
                            format="{0:dd MMM yyyy}"></kendo-excelexport-column>
                        <kendo-excelexport-column field="meterReading" title="Meter Reading"></kendo-excelexport-column>
                        <kendo-excelexport-column field="batteryVoltage"
                            title="Battery Voltage"></kendo-excelexport-column>

                        <kendo-excelexport-column field="occupant" title="Occupant"></kendo-excelexport-column>
                    </kendo-excelexport>


                    <!-- pdf start -->

                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                            [kendoGridBinding]="selectedRowdataOnOff.length>0 ? selectedRowdataOnOff:metergridData.data"
                            #pdfGrid>
                            <kendo-grid-column field="deviceSRNo" title="Device Sr.No"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                            <kendo-grid-column field="utilityType" title="Utility"></kendo-grid-column>
                            <kendo-grid-column field="timeStamp" title="Time Stamp">
                                <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.currentDate | date: 'dd MMM yyyy' }}
                                </ng-template> -->
                            </kendo-grid-column>

                            <kendo-grid-column field=" meterReading" title="Meter Reading"></kendo-grid-column>
                            <kendo-grid-column field="meterReadingDG" title="DG Meter Reading "></kendo-grid-column>
                            <kendo-grid-column field="meterReadingEB" title="EB Meter Reading"></kendo-grid-column>
                            <kendo-grid-column field="onOffStatus" title="On/Off Status"></kendo-grid-column>

                            <kendo-grid-column field="batteryLevel" title="Battery Level"></kendo-grid-column>
                            <kendo-grid-column field="batteryVoltage" title="Battery Voltage"></kendo-grid-column>
                            <!-- <kendo-grid-column field="statusBits" title="Status Bit"></kendo-grid-column> -->
                            <kendo-grid-column field="occupant" title="Occupant"></kendo-grid-column>
                            <!--  <kendo-grid-pdf fileName="MeterConfig.pdf" [allPages]="true" paperSize="A4"
                                [landscape]="true"></kendo-grid-pdf> -->
                            <kendo-grid-excel fileName="Meter Reading.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>