@if(billingType =='Prepaid'){
<!--  consumption History-->
<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
    <kendo-grid [data]="walletHistoryExtendedgridData" [navigable]="true" kendoGridFocusable [sortable]="true"
        [sort]="walletHistoryState.sort" [skip]="walletHistoryState.skip" filterable="menu"
        [columnMenu]="{ filter: false }" [resizable]="true">

        <!--  <kendo-grid-column field="utility" title="Utility"></kendo-grid-column> -->
        <kendo-grid-column field="utility" title="Utility">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>
                    {{ dataItem.utility === 'Electricity'
                    ? dataItem.utility
                    : (dataItem.utility === '' || dataItem.utility == null ? 'N/A' :
                    dataItem.utility) }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="meterNo" title="Device Sr No"></kendo-grid-column>
        <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
        <kendo-grid-column field="consumptionUnits" title="Consumption" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{
                dataItem.consumptionUnits == null || dataItem.consumptionUnits === ''
                ? '-'
                : dataItem.utility == 'Water'
                ? dataItem.consumptionUnits + ' L'
                : dataItem.utility == 'Electricity'
                ? '-'
                : dataItem.utility == 'Gas'
                ? dataItem.consumptionUnits + ' m³'
                : '-'
                }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="consumptionUnitsEB" title="Consumption Units EB" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.consumptionUnitsEB !== null ? (dataItem.utility == 'Electricity'||dataItem.utility ==
                'TriSource')? dataItem.consumptionUnitsEB
                + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="consumptionUnitsDG" title="Consumption Units DG" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.consumptionUnitsDG !== null ? dataItem.utility == 'Electricity'? dataItem.consumptionUnitsDG
                + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="finalMeterReadingSolar" title="Consumption Units Solar" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.finalMeterReadingSolar !== null ? dataItem.utility == 'TriSource'?
                dataItem.finalMeterReadingSolar
                + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="unitRate" title="Unit Rate" [hidden]="true">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.utility !== 'Electricity'?(dataItem.unitRate | currency: 'INR':'symbol':'1.2-2'):'-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="unitRateDG" title="Units Rate DG" filter="numeric" [hidden]="true">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.unitRateDG !== null ? (dataItem.utility == 'Electricity' ||dataItem.utility == 'TriSource')?
                (dataItem.unitRateDG
                | currency: 'INR':'symbol':'1.2-2'):'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="unitRateEB" title="Units Rate EB" filter="numeric" [hidden]="true">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.unitRateEB !== null ?(dataItem.utility == 'Electricity' ||dataItem.utility == 'TriSource')?
                (dataItem.unitRateEB
                | currency: 'INR':'symbol':'1.2-2'):'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="unitRateSolar" title="Units Rate Solar" filter="numeric" [hidden]="true">
            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.unitRateSolar !== null ?dataItem.utility == 'TriSource'? (dataItem.unitRateSolar
                | currency: 'INR':'symbol':'1.2-2'):'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Recharge amount" title="Recharge Amount">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.rechargeAmount | currency: 'INR':'symbol':'1.2-2' }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="amountDeducted" title="Amount Deducted">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.amountDeducted | currency: 'INR':'symbol':'1.2-2' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="availableBalance" title="Available Balance">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [hidden]="true" field="initialMeterReading" title="Initial Reading"
            filter="numeric"></kendo-grid-column>

        <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReading"
            title="Final Reading"></kendo-grid-column>
        <kendo-grid-column [hidden]="true" filter="numeric" field="initialMeterReadingEB"
            title="Initial Meter Reading EB">

            <ng-template kendoGridCellTemplate let-dataItem>

                {{ dataItem.initialMeterReadingEB !== null ? (dataItem.utility == 'Electricity' ||dataItem.utility ==
                'TriSource')?
                dataItem.initialMeterReadingEB + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [hidden]="true" filter="numeric" field="initialMeterReadingDG"
            title="Initial Meter Reading DG">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.initialMeterReadingDG !== null ?(dataItem.utility == 'Electricity' ||dataItem.utility ==
                'TriSource')?
                dataItem.initialMeterReadingDG + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [hidden]="true" filter="numeric" field="initialMeterReadingSolar"
            title="Initial Meter Reading Solar">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.initialMeterReadingSolar !== null ?dataItem.utility == 'TriSource'?
                dataItem.initialMeterReadingSolar + ' kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>


        <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReadingEB" title="Final Meter Reading EB">

            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.finalMeterReadingEB !== null ?
                (dataItem.utility == 'Electricity' ||dataItem.utility =='TriSource')? dataItem.finalMeterReadingEB + '
                kWh':'-'
                : '-' }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReadingDG" title="Final Meter Reading DG">

            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.finalMeterReadingDG !== null ?
                (dataItem.utility == 'Electricity' ||dataItem.utility =='TriSource')? dataItem.finalMeterReadingDG + '
                kWh':'-'
                : '-' }}
            </ng-template>

        </kendo-grid-column>
        <kendo-grid-column [hidden]="true" filter="numeric" field="finalMeterReadingSolar"
            title="Final Meter Reading Solar">

            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.finalMeterReadingSolar !== null ?
                (dataItem.utility == 'Electricity' ||dataItem.utility =='TriSource')? dataItem.finalMeterReadingSolar +
                ' kWh':'-'
                : '-' }}
            </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="previousReadingDate" [hidden]="true" title="Initial Reading Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.previousReadingDate | date: 'dd MMM yyyy '}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="finalReadingDate" title="Final Reading Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.finalReadingDate | date: 'dd MMM yyyy '}}
            </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="serviceName" title="Service Name" [hidden]="true"></kendo-grid-column>
        <kendo-grid-column field="gstAmount" title="Gst Amount" [hidden]="true"></kendo-grid-column>
        <kendo-grid-column field=" gstPer" title="Gst Percentage" [hidden]="true"></kendo-grid-column>
        <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex align-items-center">
                    <i class="fas fa-info-circle text-success" style="cursor: pointer;" kendoTooltip
                        title="View Consumption Details" (click)="viewconsumption(dataItem)">
                    </i>
                </div>
            </ng-template>
        </kendo-grid-command-column>

    </kendo-grid>
</div>}
@else{
<!-- BIll History -->
<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
    <kendo-grid [data]="walletHistoryExtendedgridData" scrollable="none" [navigable]="true" kendoGridFocusable
        [sort]="walletHistoryState.sort" [skip]="walletHistoryState.skip" filterable="menu"
        [columnMenu]="{ filter: false }" [resizable]="true">
        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
            class="no-tooltip"></kendo-grid-checkbox-column>
        <kendo-grid-column field="billNo" title="Bill Number"></kendo-grid-column>
        <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
        <kendo-grid-column field="billDate" title="Bill Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.billDate | date: 'dd MMM yyyy hh:mm a'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paymentDate" title="Payment Date">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.paymentDate | date: 'dd MMM yyyy hh:mm a'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
        <kendo-grid-column field="meterNo" title="Device Sr No"></kendo-grid-column>
        <kendo-grid-column field="macAddress" title="Mac Address" [hidden]="true"> </kendo-grid-column>
        <kendo-grid-column field="totalAmount" title="Bill Amount" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.totalAmount | currency: 'INR':'symbol':'1.2-2' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paymentMode" title="Mode of Payment" [hidden]="true"></kendo-grid-column>
        <kendo-grid-column field="paymentStatus" title="Payment Status">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.paymentStatus  === 'Paid'" class="text-success"> {{
                    dataItem.paymentStatus }}</span>
                <span *ngIf="dataItem.paymentStatus === 'Success'" class="text-success">{{
                    dataItem.paymentStatus
                    }}</span>
                <span *ngIf="dataItem.paymentStatus === 'captured'" class="text-danger">{{
                    dataItem.paymentStatus
                    }}</span>
                <span *ngIf="dataItem.paymentStatus === 'UnPaid'" class="text-danger">{{
                    dataItem.paymentStatus
                    }}</span>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex">
                    <button class="btn btn-success btn-sm d-flex align-items-center " tooltip="Go to Project Details"
                        (click)="viewBillHistory(dataItem)">
                        <i class="fas fa-info-circle"></i>
                    </button>
                </div>
            </ng-template>
        </kendo-grid-command-column> -->
        <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="d-flex">
                    <i class="fas fa-info-circle text-success" kendoTooltip title="View Bill History"
                        (click)="viewBillHistory(dataItem)" style="cursor: pointer;">
                    </i>
                </span>
            </ng-template>
        </kendo-grid-command-column>


    </kendo-grid>
</div>
}